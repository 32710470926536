body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
}

:root {
  --Hg-Navbar: 60px;
  --D0: none;
  --Subs-Navbar: none;
  --Nav-subs: none;
  --Sidebar: 260px;
  --Con-tent: 270px;
  --Subs: 320px;
  --Second-Side: -500px;
  --Subs-media: 0px;
  --Paginate: 350px;
}

.max-wd_30 {
  max-width: 30px;
}

.max-hd_30 {
  max-height: 30px;
}

.max-wd_40 {
  max-width: 40px;
}

.max-hd_40 {
  max-height: 40px;
}

.cont-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.center-objects {
  display: flex;
  align-items: center;
}

.ft-family-monospace {
  font-family: monospace;
}

.ft-weight_bold {
  font-weight: bold;
}

.ft-weight_700 {
  font-weight: 700;
}

.itm-center {
  align-items: center;
}

.dp-flex {
  display: flex;
}

.dp-none {
  display: none;
}

.dp-grid {
  display: grid;
}

.dp-block {
  display: block;
}

.bottom-0 {
  bottom: 0;
}

.list-none {
  list-style: none;
}

.ft-size_8 {
  font-size: 8px;
}

.ft-size_10 {
  font-size: 10px;
}

.ft-size_11 {
  font-size: 11px;
}

.ft-size_12 {
  font-size: 12px;
}

.ft-size_13 {
  font-size: 13px;
}

.ft-size_14 {
  font-size: 14px;
}

.ft-size_15 {
  font-size: 15px;
}

.ft-size_18 {
  font-size: 18px;
}

.ft-size_20 {
  font-size: 20px;
}

.ft-size_25 {
  font-size: 25px;
}

.mg-left-15 {
  margin-left: -15px;
}

.mg-left_16 {
  margin-left: 16px;
}

.weight-bold {
  font-weight: bold;
}

.opacity-05 {
  opacity: .5;
}

.opacity-07 {
  opacity: 0.7;
}

.color-white {
  color: #fff;
}

.color-545b64 {
  color: #545b64;
}

.color-black {
  color: #000;
}

.color-3dd0f8 {
  color: #3dd0f8;
}

.color-FFCB8B {
  color: #FFCB8B;
}

.color-3DD0F8 {
  color: #3DD0F8
}

.color-FF5572 {
  color: #FF5572;
}

.color-C3E88D {
  color: #C3E88D;
}

.color-C792EA {
  color: #C792EA;
}

.color-BEC5D4 {
  color: #BEC5D4;
}

.color-82AAFF {
  color: #82AAFF;
}

.color-89DDFF {
  color: #89DDFF;
}

.color-blue {
  color: blue;
}

.color-green {
  color: green;
}

.color-red {
  color: red;
}

.color-err {
  color: #d13333;
}

.color-cstm {
  color: hsl(0, 0%, 100%, .6);
}

.bg-C3E88D {
  background-color: #C3E88D;
}

.bg-white {
  background-color: #fff;
}

.bg-red {
  background-color: red;
}

.bg-green {
  background-color: green;
}

.bg-232f3e {
  background: #232f3e;
}

.bg-313c42 {
  background: #313c42;
}

.pd-10-0 {
  padding: 10px 0;
}

.pd-0 {
  padding: 0 !important;
}

.pd-2 {
  padding: 2px;
}

.pd-5 {
  padding: 5px;
}

.pd-10 {
  padding: 10px;
}

.pd-15 {
  padding: 15px;
}

.pd-20 {
  padding: 20px;
}

.pd-left_4 {
  padding-left: 4px;
}

.pd-left_5 {
  padding-left: 5px;
}

.pd-left_10 {
  padding-left: 10px;
}

.pd-left_15 {
  padding-left: 15px;
}

.pd-left_20 {
  padding-left: 20px;
}

.pd-right_5 {
  padding-right: 5px;
}

.pd-right_10 {
  padding-right: 10px;
}

.pd-right_20 {
  padding-right: 20px;
}

.pd-top_50 {
  padding-top: 50px;
}

.pd-top_100 {
  padding-top: 100px;
}

.pd-bottom_10 {
  padding-bottom: 10px;
}

.pd-bottom_20 {
  padding-bottom: 20px;
}

.wd-500 {
  width: 500px;
}

.wd-50p {
  width: 50%;
}

.wd-100p {
  width: 100%;
}

.wd_90p {
  width: 90%;
}

.tx-center {
  text-align: center;
}

.tx-left {
  text-align: left;
}

.tx-right {
  text-align: right;
}

.mg-auto {
  margin: auto
}

.mg-top_1 {
  margin-top: 1px;
}

.mg-top_7 {
  margin-top: 7px;
}

.mg-top_10 {
  margin-top: 10px;
}

.mg-top_20 {
  margin-top: 20px;
}

.mg-bottom_7 {
  margin-bottom: 7px;
}

.mg-bottom_50 {
  margin-bottom: 50px;
}

.mg-bottom_100 {
  margin-bottom: 100px;
}

.mg-left_10 {
  margin-left: 10px;
}

.mg-left_50 {
  margin-left: 50px;
}

.mg-right_10 {
  margin-right: 10px;
}

.outline-none {
  outline: none
}

.select-none {
  user-select: none;
}

.cursor-pointer {
  cursor: pointer;
}

.dg-nowrap {
  white-space: nowrap;
}

.bd-none {
  border: none;
}

.bd-left_solid1_f1 {
  border-left: 1px solid #f1f1f1;
}

.bd-right_solid1_f1 {
  border-right: 1px solid #f1f1f1;
}

.bd-left_groove {
  border-left: 1px groove #1e1e2f;
}

.bd-right_groove {
  border-right: 1px groove #1e1e2f;
}

.bd-radius_10 {
  border-radius: 10px;
}

.bd-radius_2 {
  border-radius: 2px;
}

.bd-radius_5 {
  border-radius: 5px;
}

.pst-absolute {
  position: absolute;
}

.pst-relative {
  position: relative;
}

.img-ef {
  height: 150px;
  width: 150px;
  margin: auto;
  display: block;
}

.provider {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 40;
  left: 0;
  display: flex;
  opacity: 1;
  justify-content: center;
}

.full-page {
  min-height: 100vh;
  height: auto;
  position: relative;
  top: 0;
}

.container {
  position: relative;
  margin: auto;
}

.order-form {
  flex-direction: column;
  padding: 100px 15px 0 15px;
  width: 50%;
  margin: auto;

}

.form {
  width: 50%;
  color: #000;
  flex-direction: column;
  margin: auto;
  background: #fff;
  border-radius: 5px;
}

.form-data {
  flex-direction: column;
  padding: 10px;
}

.inp-data {
  height: 35px;
  border-radius: 8px;
  padding-left: 10px;
  background-color: #E8F0FE;
}

.btt-submit {
  border: none;
  margin: 50px;
  height: 35px;
  border-radius: 15px;
  background: #3DD0F8;
}

.btt-submit:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}



@keyframes spinner {
  0% {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }

  100% {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}

.spin {
  opacity: 1;
  position: relative;
  transition: opacity linear 0.1s;
}

.spin::before {
  animation: 2s linear infinite spinner;
  border: solid 3px #d7d2cc;
  border-bottom-color: #1e1e2f16;
  border-radius: 50%;
  content: "";
  height: 15px;
  left: 50%;
  opacity: inherit;
  position: absolute;
  transform: translate3d(-50%, -50%, 0);
  transform-origin: center;
  width: 15px;
  will-change: transform;
}

.spin_ef {
  opacity: 1;
  position: relative;
  transition: opacity linear 0.1s;
}

.spin_ef::before {
  animation: 2s linear infinite spinner;
  border: solid 3px #35d7f8;
  border-bottom-color: #1e1e2f16;
  border-radius: 50%;
  content: "";
  height: 25px;
  left: 50%;
  opacity: inherit;
  position: absolute;
  transform: translate3d(-50%, -50%, 0);
  transform-origin: center;
  width: 25px;
  will-change: transform;
}

.spin.update {
  width: 45px;
  height: 16px;
  top: 8px;
}

.content-prvt {
  flex-direction: column !important;
  height: 100%;
}

.all-content {
  flex-direction: row !important;
  height: 100%;
}

.nav-bar {
  width: 100%;
  background: #232f3e;
  color: #fff;
  display: flex;
  top: 0;
  position: fixed;
  height: 40px;
  flex-direction: row;
  justify-content: space-between;
  z-index: 110;
  transition: all .5s;
}

.nav-sidebar {
  color: #fff;
  background: #232f3e;
  visibility: hidden;
  flex-direction: row;
  display: flex;
  margin: 0;
  margin-right: 30px;
  z-index: 1000;
  transition: all .5s;
}

.side-bar {
  list-style: none;
  overflow: scroll;
  color: #232f3e;
  background: #ffffff;
  height: inherit;
  width: var(--Sidebar);
  position: fixed;
  z-index: 100;
  margin-top: 40px;
  border-right: 1px solid #f8f8f8;
  box-shadow: 1px 0px 5px #8a8787;
  transition: all .5s;
}

.side-bar::-webkit-scrollbar {
  width: 0px;
}

.hover {
  display: flex;
  flex-direction: column !important;
  flex-wrap: wrap;
}

.hover .short-one:hover {
  cursor: pointer;
  color: #EC7211;
  background: #232f3e;
  border-radius: 8px 0 0 8px;
}

.hover .sub-show {
  margin: 1px 0 1px 0;
  display: block;
}

.hover .sub-show.hide a span {
  opacity: 0;
  visibility: hidden;
  transition: all .3s;
}

.hover .sub-show .subs {
  color: #fff;
  display: none;
  box-shadow: 10px 10px 10px #f1f1f1;
  position: fixed;
  left: var(--Subs);
  margin-top: -30px;
  padding: 10px 10px 10px 20px;
  background: #232f3e;
  border-radius: 0px 8px 8px 0;
  border-right-style: outset;
}

.hover .sub-show:hover .subs {
  display: block;
}

.subs .select-subs {
  display: block;
  padding: 0.25rem 1.25rem;
  margin-bottom: 10px;
}

.subs .select-subs:hover {
  color: #fff;
  background: #EC7211;
  border-radius: 8px;
}

.hambg {
  display: block;
  position: absolute;
  left: 20px;
  bottom: 45px;
  cursor: pointer;
}

.con-tent {
  position: relative;
  padding: 0 20px;
  padding-bottom: 20px;
  margin-left: var(--Con-tent);
  width: 100%;
  margin-top: 40px;
  overflow: auto;
  transition: all .5s;
}

.list-navi .select span {
  margin-left: 20px;
  cursor: pointer;
}

.select .active {
  border-bottom: solid #EC7211;
}

.tbl {
  flex-direction: column;
}

.for-searching {
  position: relative;
  margin-bottom: 15px;
}

.glass {
  position: absolute;
  font-size: 20px;
  top: 8px;
  left: 8px;
}

.search {
  border-radius: 8px;
  height: 35px;
  padding-left: 35px;
  width: 45px;
  transition: all .5s;
}

.search:focus {
  width: 400px;
  transition: all .5s;
}

.content-tbl {
  overflow-wrap: anywhere;
}

.table-us {
  width: 100%;
}

.tr-head {
  border-radius: 8px 8px 0 0;
  width: 100%;
  grid-template-columns: 8% 15% 42% 5% 5% 5% 10% 10%;
}

.tr-body {
  width: 100%;
  counter-reset: grid-items;
  grid-template-columns: 8% 15% 42% 5% 5% 5% 10% 10%;
}

.tr-body td {
  padding: 10px;
}

.paginate {
  justify-content: space-between;
  border-radius: 8px;
  position: fixed;
  align-items: center;
  flex-wrap: wrap;
  left: var(--Paginate);
  right: 0;
  bottom: 0;
  height: 50px;
  background: #fff;
  box-shadow: 5px -5px 10px #f1f1f1;
  transition: all .5s;
}

.num-cant {
  padding-left: 10px;
  font-size: 11px;
}

.btn-pag {
  display: flex;
  padding: 5px;
  align-items: center
}

.page-current {
  padding-right: 10px;
}

.table-add select,
textarea {
  padding-bottom: 6px;
  padding-top: 6px;
  border-radius: 4px;
  border: none;
  background-color: #f1f3f4;
  color: #202124;
}

.table-add input {
  padding-bottom: 6px;
  padding-top: 6px;
  border-radius: 4px;
  border: none;
  background-color: #f1f3f4;
  color: #202124;
}

.table-add input[type=file]::file-selector-button {
  cursor: pointer;
  outline: none;
  border: none;
  color: #fff;
  background: #4f4f56;
  width: 100px;
  border-radius: 8px;
  padding-bottom: 2px;
  padding-top: 2px;
}

.table-add label.color-err {
  margin-left: 20px;
  position: absolute;
}

.table-add .api {
  cursor: text;
  user-select: text;
  position: absolute;
  margin-left: 220px;
  border-radius: 8px;
  font-size: 12px;
  border-style: dotted;
  border-width: 1px;
}

.table-add td {
  border-bottom: rgba(0, 0, 0, 0.05) solid 1px;
  padding: 12px 50px 12px 0;
}

.table-add.custom td {
  border-bottom: rgba(0, 0, 0, 0.05) solid 1px;
  padding: 10px;
}

.table-add.balance td {
  padding: 14px 20px 13px 0 !important;
}

.table-add button {
  border: medium none;
  border-radius: 2px;
  padding: 3px;
  color: #FFF;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  text-align: center;
  background-color: #313c42;
}

.buttom_up {
  border: medium none;
  border-radius: 2px;
  padding: 3px;
  color: #FFF;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  text-align: center;
  background-color: #313c42;
}

.buttom_create {
  border: medium none;
  border-radius: 2px;
  padding: 3px;
  color: #FFF;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  text-align: center;
  background-color: #006631;
}

.buttom_delete {
  border: medium none;
  border-radius: 2px;
  padding: 3px;
  color: #FFF;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  text-align: center;
  background-color: #c30000;
}

.user-select {
  user-select: all !important;
}

.input_up {
  padding-bottom: 6px;
  padding-top: 6px;
  user-select: none;
  border-radius: 4px;
  border: none;
  background-color: #f1f3f4;
  color: #202124;
  padding-left: 5px;
}

.active {
  justify-content: space-between;
}

.active .delete {
  background: #c30000;
}

.btn-active {
  font-size: 20px;
}

.btn-desactived {
  font-size: 20px;
  transform: scaleX(-1);
}

.ul-min {
  padding: 0;
}

.btn-back {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  border: medium none;
  border-radius: 4px;
  color: #FFF;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
  background-color: #313c42;

}

.back {
  color: #fff;
  font-size: 14px;
}

.modal {
  width: 100%;
  top: 0;
  position: fixed;
  left: 0;
  height: 100%;
  margin: 0;
  padding: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 100;
}

.modal-content {
  position: fixed;
  border-radius: 10px;
  background: #fff;
  width: 500px;
  height: 200px;
  margin: auto auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.modal-content .content {
  margin: 25px;
}

.content {
  position: relative;
}

.content input {
  height: 15px;
  width: 100px;
  outline: none;
  border-radius: 8px;
  text-align: center;
  margin-left: 25px;
}

.close-modal {
  height: 25px;
  width: 25px;
  border: 0;
  border-radius: 8px;
  background: #1e1e2f;
  color: #fff;
  top: -15px;
  right: -15px;
  position: absolute;
}

.delete-modal {
  position: absolute;
  bottom: -50px;
  left: 0;
  height: 25px;
  border-radius: 8px;
  background: #d13333;
  border: 0;
  color: #fff;
}

.disabled {
  cursor: not-allowed;
  opacity: .5;
}

.ul-in {
  width: 90%;
  padding-bottom: 10px;
  white-space: nowrap;
}

.con-tent::-webkit-scrollbar {
  background: #e7e7eb;
  border-radius: 8px;
  height: 7px;
}

.con-tent::-webkit-scrollbar-thumb {
  background: #91919c;
  border-radius: 8px;
}

.default_dg_table {
  color: #1c1e21;
  width: 100%;
}

.sync {
  width: 100px !important;
  height: 40px !important;
}

.services {
  padding: 5px !important
}

.images {
  grid-template-columns: repeat(5, auto);
  text-align: center;
}

.bttns {
  justify-content: space-between;
  width: 200px;
}

.default_dg_td {
  padding: 7px;
  border-bottom: 1px solid #f1f1f1;
  border-top: 1px solid #f1f1f1;
  font-size: 14px;
}

.bd-bt_solid {
  border-bottom: 1px solid #575757;
}


.default_dg_td_create {
  padding: 3px;
  border-bottom: 1px solid #f1f1f1;
  border-top: 1px solid #f1f1f1;
  font-size: 13px;
}

.default_dg_a_header {
  color: #444;
  text-decoration: none;
  font-size: 12px;
}

.dg_nowrap {
  white-space: normal;
}

.bd_left_solid1_f1 {
  border-left: 1px solid #f1f1f1;
}

.bd_left_solid1_f1 {
  border-left: 1px solid #f1f1f1;
}

.fieldlabel {
  letter-spacing: 0;
  padding: 15px 0;
  text-align: left;
  vertical-align: middle;
  border-bottom: rgba(0, 0, 0, 0.05) solid 1px;
  white-space: normal;
  min-width: 100px;
  font-size: 14px;
  width: 200px;
}

.pd-0-10p {
  padding: 0 10px;
}

.wd_300i {
  width: 300px !important;
}

.line {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 4px;
  background: linear-gradient(#EC7211 0 0) center/0% 100% no-repeat;
  animation: l 1s linear infinite alternate;
}

@keyframes l {
  to {
    background-size: 100% 100%
  }
}

.nav-sideopen {
  display: none;
}

.nav-sideopen>a {
  display: none;
  position: absolute;
  cursor: pointer;
  right: 20px;
  top: 10px;
}

.sub-show {
  display: flex;
  align-items: center;
}

.nav-link:hover {
  border-radius: 8px;
  background: #fff;
  color: #000
}

.nav-link {
  padding: 0px 15px;
  cursor: pointer;
}

.subs-nav {
  display: flex;
  top: 40px;
  min-width: 150px;
  position: absolute;
  flex-direction: column;
}

.sub-nav {
  margin: 5px;
  padding: 5px 10px;
  cursor: pointer;
}

.sub-nav:hover {
  border-radius: 8px;
  background: #fff;
  color: #000;
}

.pn {
  font-weight: bold;
  background: -webkit-linear-gradient(#ffffff, #cb8e51);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}





































@media (max-width: 1280px) {
  /* .tr-body {
    grid-template-columns: 8% 21% 44% 8% 8% 12%;
  }

  .tr-head {
    grid-template-columns: 8% 21% 44% 8% 8% 12%;
  } */
}





@media (max-width: 700px) {
  .form {
    width: 100%;
  }

  .nav-bar {
    z-index: 30;
    align-items: baseline;
    flex-direction: column;
    height: var(--Hg-Navbar);
    transition: all .5s;
    justify-content: flex-start;
  }

  .hover {
    display: none;
  }

  .nav-sidebar {
    display: var(--Subs-Navbar);
    visibility: visible;
    margin: 0;
    width: 100%;
    padding: 0;
    top: var(--Hg-Navbar);
  }

  .sub-show {
    flex-direction: column;
    align-items: baseline;
    position: relative;
    height: auto;
    padding: 5px;
    margin-left: 15px;
  }

  .nav-sidebar .sub-show .subs {
    color: black;
    display: none;
    box-shadow: -8px 5px 10px #f1f1f1;
    position: fixed;
    right: 19.5px;
    margin-top: -32px;
    padding: 10px 10px 10px 20px;
    background: #fff;
    border-radius: 8px 0 0 8px;
  }

  .nav-sidebar .sub-show:hover .subs {
    display: block;
  }

  .nav-sidebar .subs .select-subs {
    display: block;
    padding: 0.25rem 1.25rem;
    margin-bottom: 10px;
  }

  .nav-sidebar .subs .select-subs:hover {
    color: #fff;
    background: #1e1e2f;
    border-radius: 8px;
  }

  .side-bar {
    opacity: 0;
    visibility: hidden;
    display: none;
    transition: all .2s;
  }

  .con-tent {
    margin-left: 0px;
  }

  .paginate {
    left: 20px;
  }

  .ul-in {
    padding-left: 0px;
  }

  .nav-link {
    padding: 5px 15px;
  }

  .nav-link:hover {
    border-radius: 8px;
    background: #fff;
    color: #232f3e;
  }

  .subs-nav {
    display: flex;
    margin-left: 40px;
    width: 100%;
    padding: 5px 10px;
    position: relative;
    flex-direction: column;
    top: 0;
  }

  .nav-sideopen {
    display: block;
    width: 100%;
  }

  .nav-sideopen>a {
    display: block;
    position: absolute;
    cursor: pointer;
    right: 20px;
    top: 10px;
  }

}


@media (max-width: 700px) {

  .tr-head {
    grid-template-columns: 10% 25% 50% 15%;
  }

  .tr-body {
    grid-template-columns: 10% 25% 50% 15%;
    grid-template-rows: auto;
  }

  .users th:nth-child(4),
  .users th:nth-child(5),
  .users td:nth-child(4),
  .users td:nth-child(5) {
    display: none;
  }

  .table-add {
    min-width: 420px;
  }

  .modal-content {
    width: 350px;
    height: 200px;
  }

  .services .tr-body {
    grid-template-rows: 150px;
    grid-template-columns: 10% 28% 32% 10% 10% 10%;
  }

  .ul-in {
    padding-right: 140px;
  }

  .form {
    width: 300px;
  }

  .order-form {
    width: auto;
    margin: 0;
  }

  .nav-link {
    width: 100%;
  }

}